<template>
  <div>
    <div v-if="!$vuetify.breakpoint.smAndDown">
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="6"
          v-for="playlist in rcEngineData.playlists"
          :key="playlist.name"
        >
          <PlaylistNoGroups :playlist="playlist" :rc-engine-data="rcEngineData">
          </PlaylistNoGroups>
        </v-col>
      </v-row>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mt-2">
        <v-toolbar>
          <v-tabs v-model="tab" centered>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab
              v-for="item in rcEngineData.playlists"
              :key="'tabTitle' + item.name"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-card-text>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item
              v-for="playlist in rcEngineData.playlists"
              :key="'tabContent' + playlist.name"
            >
              <PlaylistNoGroups
                :playlist="playlist"
                :rc-engine-data="rcEngineData"
              >
              </PlaylistNoGroups>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import PlaylistNoGroups from "@/components/engines/remote-control/PlaylistNoGroups"

export default {
  components: {
    PlaylistNoGroups,
  },
  data() {
    return {
      tab: null,
    }
  },
  created: function () {},
  mounted: function () {
    if (!this.$can("rc.playlist", "ws_rc")) {
      this.$router.push({ name: "home" }).catch((err) => {})
    }
  },
  methods: {},
  props: {
    playlist: Array,
    rcEngineData: Object,
  },
  // computed: Object.assign(
  //     mapGetters({
  //         rcEngineData: 'getRcEngineData',
  //     }),
  //     {

  //     },
  // ),
}
</script>
