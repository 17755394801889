var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mobile' : ''},[_c('v-toolbar',[_c('span',{staticClass:"headline",attrs:{"data-testid":"playlist-title"}},[_vm._v(" "+_vm._s(_vm.playlist.name)+" ")]),_c('v-spacer'),_c('h3',{class:{
        'success--text': _vm.playlist.status == 'Runing',
        'orange--text text--lighten-1': _vm.playlist.status == 'Pause',
      },attrs:{"data-testid":"playlist-status"}},[_vm._v(" "+_vm._s(_vm.playlist.status == "Runing" ? "Playing" : "Paused")+" ")])],1),_c('v-card-text',{staticClass:"playlist-wrapper",attrs:{"id":'rc-playlist-block' + _vm.playlist.name,"data-testid":"container-playlist"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.playlist.parsedItems),function(item,index){return _c('v-list-item',{key:index,class:[
          { selected: _vm.playlist.index == item.pos },
          { even: index % 2 === 0 },
          'item-rc',
        ],on:{"click":function($event){return _vm.playCurrentFile($event, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(index + 1 + ".")+" "+_vm._s(item.name))])],1)],1)}),1)],1),_c('v-card-actions',[_c('div',{staticClass:"playlist-actions-wrapper"},[_c('v-btn',{staticClass:"rc-playes-button",attrs:{"text":"","color":"light-blue","data-testid":"btn-prev"},on:{"click":function($event){return _vm.playPrevFile()}}},[_c('v-icon',[_vm._v("skip_previous")])],1),(!(_vm.playlist.status === 'Runing'))?_c('v-btn',{staticClass:"rc-playes-button",attrs:{"text":"","color":"light-blue","data-testid":"btn-start"},on:{"click":function($event){return _vm.startItem()}}},[_c('v-icon',[_vm._v("play_arrow")])],1):_vm._e(),(_vm.playlist.status === 'Runing')?_c('v-btn',{staticClass:"rc-playes-button",attrs:{"text":"","color":"light-blue","data-testid":"btn-pause"},on:{"click":function($event){return _vm.pauseItem()}}},[_c('v-icon',[_vm._v("pause")])],1):_vm._e(),_c('v-btn',{staticClass:"rc-playes-button",attrs:{"text":"","color":"light-blue","data-testid":"btn-next"},on:{"click":function($event){return _vm.playNextFile()}}},[_c('v-icon',[_vm._v("skip_next")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }