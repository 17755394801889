var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-testid":"container-rc-playlists"}},[(!_vm.haveGeneralUserRightsViewPage)?[_c('div',{attrs:{"data-testid":"rc-notification-no-rights"}},[_vm._v(" You don't have rights for RC for this engine... ")])]:[_c('div',[_c('v-toolbar',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mobile-mode' : '',attrs:{"dark":"","extended":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndDown)?{key:"extension",fn:function(){return [_c('v-spacer'),_c('v-toolbar-title',{staticClass:"mr-2",attrs:{"data-testid":"sm-project"}},[_vm._v(_vm._s(_vm.rcEngineData.project || "Noname"))]),_c('v-toolbar-title',{staticClass:"mr-2"},[_c('span',{class:_vm.rcEngineData._engine && _vm.rcEngineData._engine.is_online
                  ? 'green--text'
                  : 'red--text',attrs:{"data-testid":"sm-engine-status"}},[_c('span',{staticClass:"overline",attrs:{"data-testid":"sm-engine-status-icon"}},[_c('i',{staticClass:"fa fa-circle mx-2"})]),_c('span',{staticClass:"font-weight-regular",attrs:{"data-testid":"sm-engine-status-text"}},[_vm._v(" "+_vm._s(_vm.rcEngineData._engine && _vm.rcEngineData._engine.is_online ? "Online" : "Offline")+" ")])])]),_c('v-toolbar-title',{staticClass:"mr-2"},[_c('span',{class:_vm.rcEngineData.is_active ? 'green--text' : 'red--text',attrs:{"data-testid":"sm-rc-status"}},[_c('span',{staticClass:"overline"},[_c('i',{staticClass:"fa fa-circle mx-2",attrs:{"data-testid":"sm-rc-status-icon"}})]),_c('span',{staticClass:"font-weight-regular",attrs:{"data-testid":"sm-rc-status-text"}},[_vm._v(" RC ")])])])]},proxy:true}:null],null,true)},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","text":"","small":"","to":_vm.$route.query.event
              ? `/store/event/control/${_vm.$route.query.event}`
              : '/engines/remote-control/active',"data-testid":"btn-rc-exit"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-arrow-left-bold-circle-outline")])],1),_c('v-toolbar-title',{attrs:{"data-testid":"title-engine-name"}},[_vm._v(" "+_vm._s(_vm.getEngineName())+" ")]),_c('v-spacer'),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar-title',{staticClass:"mr-2",attrs:{"data-testid":"title-project-name"}},[_vm._v(" "+_vm._s(_vm.rcEngineData.project || "Noname")+" ")]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar-title',{staticClass:"mr-2"},[_c('span',{class:_vm.rcEngineData._engine && _vm.rcEngineData._engine.is_online
                ? 'green--text'
                : 'red--text',attrs:{"data-testid":"engine-status"}},[_c('span',{staticClass:"overline",attrs:{"data-testid":"engine-status-icon"}},[_c('i',{staticClass:"fa fa-circle mx-2"})]),_c('span',{staticClass:"font-weight-regular",attrs:{"data-testid":"engine-status-text"}},[_vm._v(" "+_vm._s(_vm.rcEngineData._engine && _vm.rcEngineData._engine.is_online ? "Online" : "Offline")+" ")])])]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar-title',{staticClass:"mr-2",attrs:{"data-testid":"engine-rc-status"}},[_c('span',{class:_vm.rcEngineData.is_active ? 'green--text' : 'red--text'},[_c('span',{staticClass:"overline"},[_c('i',{staticClass:"fa fa-circle mx-2",attrs:{"data-testid":"engine-rc-status-icon"}})]),_c('span',{staticClass:"font-weight-regular",attrs:{"data-testid":"engine-rc-status-text"}},[_vm._v(" RC ")])])]):_vm._e()],1),(_vm.rcEngineData.haveGroups)?[_c('GroupsList',{key:'playlist-items-' + _vm.rcEngineData.playlists[0].name,attrs:{"rc-engine-data":_vm.rcEngineData,"playlist":_vm.rcEngineData.playlists[0]}})]:[_c('PlaylistsNoGroups',{attrs:{"rc-engine-data":_vm.rcEngineData,"playlist":_vm.rcEngineData.playlists}})]],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }