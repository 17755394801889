<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>{{
            rcEngineData.playlists[0].name
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-list dense class="radioList">
            <v-radio-group
              v-model="activeGroup"
              :mandatory="false"
              class="mt-0"
              data-testid="radio-rc-group-root"
            >
              <v-radio
                v-for="(groupItems, name) in playlist.groupsPlaylists"
                :key="name"
                :label="name"
                :value="name"
                :data-testid="'radio-rc-group' + name"
              ></v-radio>
            </v-radio-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <template v-for="(groupItems, name) in playlist.groupsPlaylists">
      <v-col
        cols="12"
        md="4"
        :key="'col' + name"
        v-if="activeGroup == name"
        data-testid="container-group-playlist"
      >
        <Group
          :playlist="playlist"
          :rc-engine-data="rcEngineData"
          :groupItems="groupItems"
          :name="name"
          :active-group="activeGroup"
          :key="name"
        ></Group>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import Group from "@/components/engines/remote-control/Group"

export default {
  name: "GroupsList",
  components: {
    Group,
  },
  data() {
    return {
      radios: "",
      activeGroup: null,
      selectedItem: "",
      currentItem: "",
    }
  },
  props: {
    playlist: Object,
    rcEngineData: Object,
  },
  computed: {},
  methods: {
    selectItem(item) {
      this.selectedItem = item
    },
  },
}
</script>

<style lang="sass" scoped>

.radioList
  .v-radio
    padding: 6px 12px
    &.v-item--active
      background-color: rgba(0,0,0,.87) !important
      border: 2px solid rgba(0,0,0,.87)
</style>
