<template>
  <div data-testid="container-rc-playlists">
    <template v-if="!haveGeneralUserRightsViewPage">
      <div data-testid="rc-notification-no-rights">
        You don't have rights for RC for this engine...
      </div>
    </template>

    <template v-else>
      <div>
        <v-toolbar
          dark
          :class="$vuetify.breakpoint.smAndDown ? 'mobile-mode' : ''"
          :extended="$vuetify.breakpoint.smAndDown"
        >
          <v-btn
            class="mx-2"
            fab
            text
            small
            :to="
              $route.query.event
                ? `/store/event/control/${$route.query.event}`
                : '/engines/remote-control/active'
            "
            data-testid="btn-rc-exit"
          >
            <v-icon dark>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
          <v-toolbar-title data-testid="title-engine-name">
            {{ getEngineName() }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title
            class="mr-2"
            v-if="!$vuetify.breakpoint.smAndDown"
            data-testid="title-project-name"
          >
            {{ rcEngineData.project || "Noname" }}
          </v-toolbar-title>
          <v-toolbar-title class="mr-2" v-if="!$vuetify.breakpoint.smAndDown">
            <span
              :class="
                rcEngineData._engine && rcEngineData._engine.is_online
                  ? 'green--text'
                  : 'red--text'
              "
              data-testid="engine-status"
            >
              <span class="overline" data-testid="engine-status-icon">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span
                class="font-weight-regular"
                data-testid="engine-status-text"
              >
                {{
                  rcEngineData._engine && rcEngineData._engine.is_online
                    ? "Online"
                    : "Offline"
                }}
              </span>
            </span>
          </v-toolbar-title>
          <v-toolbar-title
            class="mr-2"
            v-if="!$vuetify.breakpoint.smAndDown"
            data-testid="engine-rc-status"
          >
            <span :class="rcEngineData.is_active ? 'green--text' : 'red--text'">
              <span class="overline">
                <i
                  class="fa fa-circle mx-2"
                  data-testid="engine-rc-status-icon"
                ></i>
              </span>
              <span
                class="font-weight-regular"
                data-testid="engine-rc-status-text"
              >
                RC
              </span>
            </span>
          </v-toolbar-title>
          <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown">
            <v-spacer></v-spacer>
            <v-toolbar-title class="mr-2" data-testid="sm-project">{{
              rcEngineData.project || "Noname"
            }}</v-toolbar-title>
            <v-toolbar-title class="mr-2">
              <span
                :class="
                  rcEngineData._engine && rcEngineData._engine.is_online
                    ? 'green--text'
                    : 'red--text'
                "
                data-testid="sm-engine-status"
              >
                <span class="overline" data-testid="sm-engine-status-icon">
                  <i class="fa fa-circle mx-2"></i>
                </span>
                <span
                  class="font-weight-regular"
                  data-testid="sm-engine-status-text"
                >
                  {{
                    rcEngineData._engine && rcEngineData._engine.is_online
                      ? "Online"
                      : "Offline"
                  }}
                </span>
              </span>
            </v-toolbar-title>
            <v-toolbar-title class="mr-2">
              <span
                :class="rcEngineData.is_active ? 'green--text' : 'red--text'"
                data-testid="sm-rc-status"
              >
                <span class="overline">
                  <i
                    class="fa fa-circle mx-2"
                    data-testid="sm-rc-status-icon"
                  ></i>
                </span>
                <span
                  class="font-weight-regular"
                  data-testid="sm-rc-status-text"
                >
                  RC
                </span>
              </span>
            </v-toolbar-title>
          </template>
        </v-toolbar>
        <!-- {{rcEngineData}} -->
        <template v-if="rcEngineData.haveGroups">
          <GroupsList
            :rc-engine-data="rcEngineData"
            :playlist="rcEngineData.playlists[0]"
            :key="'playlist-items-' + rcEngineData.playlists[0].name"
          ></GroupsList>
        </template>

        <template v-else>
          <PlaylistsNoGroups
            :rc-engine-data="rcEngineData"
            :playlist="rcEngineData.playlists"
          ></PlaylistsNoGroups>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
// import Group from '@/components/engines/remote-control/Group'
import { mapGetters } from "vuex"
import GroupsList from "@/components/engines/remote-control/GroupsList"
import WsConnMixin from "@/mixins/websocket-connection.js"
import PlaylistsNoGroups from "@/views/engines/remote-control/PlaylistsNoGroups"
import _ from "lodash"

export default {
  components: {
    GroupsList,
    PlaylistsNoGroups,
  },
  mixins: [WsConnMixin],
  data() {
    return {
      activeActionTab: 1,
      activeTab: 0,
      haveGeneralUserRightsViewPage: true,
      haveUserSpecificRights: true,
    }
  },
  created: function () {
    this.initApp()
  },
  mounted: function () {
    if (!this.$can("rc.playlist", "ws_rc")) {
      this.$router.push({ name: "home" }).catch((err) => {})
    }
  },
  methods: {
    initApp() {
      let self = this
      self.initWebsocketConnection()
      self.getEngineParsedInfo()
    },

    checkUserRights() {
      let data = {
        engine_name: this.$route.params.engine_name,
        project: this.$route.params.project,
      }
      return this.$store.dispatch("rcPlaylistsEngineUserRightsAction", data)
    },

    getEngineName() {
      let engineName = "Undefined"
      if (typeof this.rcEngineData !== "undefined" && this.rcEngineData) {
        engineName = this.rcEngineData?._engine?.name
      }
      return engineName
    },

    getEngineParsedInfo() {
      let self = this
      let data = {
        slug: this.$route.params.rc_key,
      }
      return this.$store.dispatch("rcPlaylistsEngineParsedInfoAction", data)
    },

    initWebsocketConnection() {
      let self = this
      self.websocket_common_conn.onmessage = function (value) {
        if (Object.keys(value).length > 0) {
          let data = JSON.parse(value.data)
          if (_.get(data, "path", "") == "1.0.ev.refresh.page") {
            self.initApp()
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      rcEngineData: "getRcEngineData",
      websocket_common_conn: "getWebsocketCommonConnection",
    }),
  },
}
</script>
