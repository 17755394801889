<template>
  <v-card :class="$vuetify.breakpoint.smAndDown ? 'mobile' : ''">
    <v-toolbar>
      <span class="headline" data-testid="playlist-title">
        {{ playlist.name }}
      </span>
      <v-spacer></v-spacer>
      <h3
        :class="{
          'success--text': playlist.status == 'Runing',
          'orange--text text--lighten-1': playlist.status == 'Pause',
        }"
        data-testid="playlist-status"
      >
        {{ playlist.status == "Runing" ? "Playing" : "Paused" }}
      </h3>
    </v-toolbar>
    <v-card-text
      :id="'rc-playlist-block' + playlist.name"
      class="playlist-wrapper"
      data-testid="container-playlist"
    >
      <v-list dense>
        <v-list-item
          v-for="(item, index) in playlist.parsedItems"
          :key="index"
          @click="playCurrentFile($event, item)"
          :class="[
            { selected: playlist.index == item.pos },
            { even: index % 2 === 0 },
            'item-rc',
          ]"
        >
          <v-list-item-content>
            <v-list-item-title
              >{{ index + 1 + "." }} {{ item.name }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <div class="playlist-actions-wrapper">
        <v-btn
          text
          color="light-blue"
          class="rc-playes-button"
          @click="playPrevFile()"
          data-testid="btn-prev"
        >
          <v-icon>skip_previous</v-icon>
        </v-btn>
        <v-btn
          v-if="!(playlist.status === 'Runing')"
          text
          color="light-blue"
          class="rc-playes-button"
          @click="startItem()"
          data-testid="btn-start"
        >
          <v-icon>play_arrow</v-icon>
        </v-btn>
        <v-btn
          v-if="playlist.status === 'Runing'"
          text
          color="light-blue"
          class="rc-playes-button"
          @click="pauseItem()"
          data-testid="btn-pause"
        >
          <v-icon>pause</v-icon>
        </v-btn>
        <v-btn
          text
          color="light-blue"
          class="rc-playes-button"
          @click="playNextFile()"
          data-testid="btn-next"
        >
          <v-icon>skip_next</v-icon>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import RCPlaylistsMixin from "@/mixins/remote-control/rc-playlists"

export default {
  mixins: [RCPlaylistsMixin],
  data() {
    return {
      // activeGroup: '',
      selectedItem: {
        name: "",
        pos: -1,
      },
      currentItem: "",
      playlistBlocked: false,
      playFileTimer: null,
    }
  },
  props: {
    playlist: Object,
    rcEngineData: Object,
  },
  watch: {
    "playlist.index": function () {
      this.playlistBlocked = false
      clearTimeout(this.playFileTimer)
      let element = document.querySelector(".item-rc")
      element.classList.remove("pending-selected")
    },
  },
  methods: {},
  computed: Object.assign(
    mapGetters({
      websocket_common_conn: "getWebsocketCommonConnection",
    }),
    {}
  ),
}
</script>

<style lang="scss">
@import "@/assets/scss/components/remote-control.scss";
</style>
<style lang="scss" scoped>
.mobile {
  height: calc(100vh - 280px);
  display: flex;
  flex-direction: column;
  header {
    flex: 0 0 auto;
  }
  .playlist-wrapper {
    flex: 1 0 auto;
  }
  .v-card__actions {
    // flex: 0 0 auto
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
  }
}
</style>
